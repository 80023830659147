import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ScheduledWorkshop from "../UI/ScheduledWorkshop";
import { useSelector } from "react-redux";
import { API_BASE_URL } from "../../Constants";
import { authActions } from "../../store/auth";
import { useDispatch } from "react-redux";

const SelectedSchedule = () => {
  const navigate = useNavigate();
  const [errorSchedule, setErrorSchedule] = useState();
  const [allSchedules, setAllSchedules] = useState([]);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const bookingStatus = useSelector((state) => state.auth.bookingStatus);
  const authEmail = useSelector((state) => state.auth.emailId);
  const scheduledItems = useSelector((state) => state.selectedWorkshop.workshop);
  const userId = useSelector((state) => state.auth.userId);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isAuth) {
      navigate("/registration");
    } else {
      if (bookingStatus === 0) {
        navigate("/registration");
      } else if (bookingStatus === 1 || bookingStatus === 2) {
        navigate("/my-schedules");
      }
    }
  }, [isAuth]);

  useEffect(() => {
    if (scheduledItems) {
      const countAr = [];
      countAr[1] = [];
      countAr[2] = [];
      countAr[3] = [];
      let newSchedules = countAr;
      Object.keys(scheduledItems).map((dateTimeKey) => {
        newSchedules[scheduledItems[dateTimeKey].event_day].push(scheduledItems[dateTimeKey]);
      });
      setAllSchedules(newSchedules);
    }
  }, [scheduledItems]);

  const handleReviewSubmit = () => {
    if (Object.keys(scheduledItems).length > 0) {
      var bodyFormData = new FormData();
      bodyFormData.append("emailId", authEmail);
      bodyFormData.append("workshop", JSON.stringify(scheduledItems));
      axios({
        method: "post",
        url: API_BASE_URL + "schedule_booking",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setErrorSchedule(response?.data?.message);
          dispatch(authActions.getUserlHandler({ user_id: userId, booking_status: 1 }));
          navigate("/my-schedules");
        })
        .catch(function (error) {
          setErrorSchedule(
            error?.response?.data?.message,
            setTimeout(() => setErrorSchedule(false), 7000),
          );
        });
    } else {
      setErrorSchedule(
        "Something went wrong",
        setTimeout(() => setErrorSchedule(false), 7000),
      );
    }
  };
  const closeAlert = () => setErrorSchedule(false);

  return (
    <Fragment>
      <div className="mySchedule">
        {errorSchedule && (
          <div className="alertPopUp">
            {errorSchedule}
            <span className="apuClose" onClick={closeAlert}>
              X
            </span>
          </div>
        )}
        <h3 className="msTitle">REVIEW YOUR SCHEDULE</h3>
        {allSchedules &&
          allSchedules.map((schedules) => (
            <div className="facilitators">
              <h4 className="msDate">{`DAY ${schedules[0]?.event_day}, ${schedules[0]?.display_date}`}</h4>
              {schedules.length > 0 &&
                schedules.map((schedule) => (
                  <>
                    {/* <p className="msTime">{schedule?.key.split("#")[2]}</p> */}
                    <ScheduledWorkshop
                      time={schedule?.key.split("#")[2]}
                      key={schedule?.event_dateid}
                      name={schedule?.facilitator_name}
                      image={schedule?.icon}
                      skills={schedule?.schedule_name}
                      actions={true}
                    />
                  </>
                ))}
            </div>
          ))}
        <button className="btn btn-primary btnWork" onClick={() => handleReviewSubmit()}>
          Confirm
        </button>
        <p className="confirmp">On confirming, schedule cannot be modified</p>
      </div>
    </Fragment>
  );
};

export default SelectedSchedule;

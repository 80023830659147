import { Fragment } from "react"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import aboutWorkShop from "../../images/aboutWork.png";

const Musicians = () => {
  return (
    <Fragment>
      <h3 className="afTitle">
        <img src={aboutWorkShop} alt="about Work Shop" />
        MUSICIANS
      </h3>
      <Tabs className="dateTabs">
        <TabList>
          <Tab>
            <div>
              25<sup>th</sup>
            </div>
            <span>Nov</span>
          </Tab>
          <Tab>
            <div>
              26<sup>th</sup>
            </div>
            <span>Nov</span>
          </Tab>
          <Tab>
            <div>
              27<sup>th</sup>
            </div>
            <span>Nov</span>
          </Tab>
        </TabList>

        <TabPanel>
          <h4 className="title">MARTIN DUBOIS</h4>
          <p>
            M'dub, multi-instrumentalist, offers a journey through sounds and
            emotions. Percussion, Hand - Pan, Kora (African Harp), Vocals,
            Mongolian Throat Chants, Flutes, Drums and Human Beat Effects!
          </p>
          <p>
            For 25 years now, he has followed the path of a musician's life with
            the evolution and experience of jazz, African music, funk, rock,
            reggae and world music.
          </p>
          <p>
            Currently, he often plays solo, with "TFM", Trance Fusion Music, the
            group "Trippy Sama" and likes to be invited to different projects in
            India and Europe.
          </p>
          <p>
            He is also heavily invested in the "Healing" dimension of sound,
            with "musical naps" and multiple collaborations with Yoga teachers
            in workshops, courses and Festivals.
          </p>
          <h4 className="title">TIRKIT GUITARRA</h4>
          <p>
            Musicians from 2 different worlds collided and realized how much
            alikeness of art is there, even continents apart. A fusion of
            Spanish Flamenco music and Indian classical music presented together
            in a beautiful Fashion where waves of notes take you along with.
          </p>
        </TabPanel>
        <TabPanel>
          <h4 className="title">REHMAT-E-NUSHRAT</h4>
          <p>
            Rehmat-e-Nusrat are a group of young musicians from Uttarakhand who
            bring a fresh new perspective to the timeless tradition of qawwali
            music.
          </p>
          <p>
            The group, formed in 2014, presents qawwalis by Ustad Nusrat Fateh
            Ali Khan, Sufiyana kalaams by the great poets Amir Khusrao, Meera
            Bai, Baba Bulleh Shah, Kabir bhajans, and original compositions.
          </p>
        </TabPanel>
        <TabPanel>
          <h4 className="title">
            ROZ ANGON [ raw – z / aung – on ] . Konkani
          </h4>
          <p>
            A musical journey accentuating Goan and Konkani musical heritage
            <br />
            By Jose Neil Gomes
          </p>
          <p>
            The songs that have been reimagined are mostly from the 60’s and the
            70’s. Sonically, Roz Angon is bridging the gap between tradition and
            modern sound. Some from popular pop albums, and some from iconic
            Konkani films, such as Nirmon, and others.
          </p>
          <p>
            Roz Angon evokes that nostalgia, with a tinge of melancholy, for the
            fear of losing it all. At a philosophical level, it's an attempt to
            address the ails of a briskly changing landscape and culture of Goa,
            to initiate a discussion of loss of the 'Goan' identity in the form
            of language, food, the people, and its flora and fauna.
          </p>
        </TabPanel>
      </Tabs>
    </Fragment>
  );
}

export default Musicians
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import back from '../../images/back.png';
import backA from '../../images/backA.png';
import favicon from '../../images/favicon.png'
import { authActions } from '../../store/auth';
import { workshopActions } from '../../store/selectedWorkshop';

import logout from '../../images/logout.png'
import logoutAct from '../../images/logoutAct.png'

const NavDirection = () => {
  const dispatch = useDispatch();
  const logoutHandler = () => {
    localStorage.removeItem('isLoggedIn');
    dispatch(authActions.logout());
    dispatch(authActions.unScheduledHandler())
    dispatch(workshopActions.addWorkshop({selectedEvents: {}}));
    dispatch(authActions.getEmailHandler(null))
    dispatch(authActions.getUserlHandler('-1'))
  }
  const isAuth = useSelector(state => state.auth.isAuthenticated);
  
  return(
    <div className='topDirNav'>
      <NavLink to={-1}>
        <img src={back} alt='previous page' className='tdnImg'/>
        <img src={backA} alt='previous page' className='tdnAct'/>
      </NavLink>
      <NavLink to='/'>
        <img src={favicon} alt='favicon moon'/>
      </NavLink>
      {isAuth &&
        <div className='tdnLogout' onClick={logoutHandler}>
          <img src={logout} alt='Logout' className='tdnImg' />
          <img src={logoutAct} alt='Logout' className='tdnAct' />
        </div>
      }
    </div>
  )
}

export default NavDirection
const Facilitator = ({ facilitator }) => {
  return (
    <div className="ficilitator">
      <a href={`facilitator/${facilitator.facilitator_id}`}>
        <img src={facilitator.icon} alt={facilitator.facilitator_name} />
        <h4>{facilitator.facilitator_name}</h4>
        <p>{facilitator.experties}</p>
      </a>
    </div>
  );
};

export default Facilitator;

import { Fragment } from "react"
import landingImage from "../images/landingImage.png"
import logo from '../images/logoMoon.png'

const LandingPage = () => {
  return(
    <Fragment>
      <img src={logo} alt="moon festival" className="lanLogo"/>
      <div className="lanImgCon">
        <img src={landingImage} className="lanImg" alt="Landing screen" />
      </div>
    </Fragment>
  )
}
 
export default LandingPage;
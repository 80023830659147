import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import InstructorProfile from "../UI/InstructorProfile";
import { API_BASE_URL } from "../../Constants";
import { useDispatch } from "react-redux";
import { workshopActions } from "../../store/selectedWorkshop";
import { authActions } from "../../store/auth";

const WorkShops = () => {
  const scheduledItems = useSelector((state) => state.selectedWorkshop.workshop);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const authEmail = useSelector((state) => state.auth.emailId);
  const bookingStatus = useSelector((state) => state.auth.bookingStatus);
  const [tabIndex, setTabIndex] = useState(0);
  const [evntData, setEventData] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [showAlert, setShowAlert] = useState();
  const [refDivs, setRefDivs] = useState([]);
  const [newStuAlert, setNewStuAlert] = useState();
  const countAr = [];
  countAr[1] = 0;
  countAr[2] = 0;
  countAr[3] = 0;
  const [daysSelectedCount, setDaysSelectedCount] = useState(countAr);
  const [totalDayWiseEventCount, setTotalDayWiseEventCount] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (authEmail === "") {
      navigate("/registration");
    } else {
      if (bookingStatus === -1) {
        navigate("/work-shop");
      } else if (bookingStatus === 0) {
        navigate("/registration");
      } else if (bookingStatus === 1 || bookingStatus === 2) {
        navigate("/my-schedules");
        dispatch(authActions.scheduledHandler());
      }
      // localStorage.setItem('isLoggedIn', '1');
    }
  }, [isAuth]);

  useEffect(() => {
    axios.get(API_BASE_URL + "1").then((response) => {
      if (response?.data[0]) {
        setEventData(response?.data[0]?.events);
      }
    });
  }, []);

  useEffect(() => {
    if (evntData) {
      let dateWiseCountAr = [];
      let tempDivIds = [...refDivs];
      Object.keys(evntData).map((singleDate, dateIndex) => {
        Object.keys(evntData[singleDate]).map((time_keys, timeIndex) => {
          tempDivIds.push(`${dateIndex}${timeIndex}`);
          if (!dateWiseCountAr[dateIndex + 1]) {
            dateWiseCountAr[dateIndex + 1] = 0;
          }
          dateWiseCountAr[dateIndex + 1]++;
        });
      });
      setRefDivs(tempDivIds);
      setTotalDayWiseEventCount(dateWiseCountAr);
    }
  }, [evntData]);

  useEffect(() => {
    if (Object.keys(scheduledItems).length > 0) {
      setSelectedItem(scheduledItems);
    }
  }, [scheduledItems]);

  const itemClickedHandler = (singleDate, time_keys, event_dateid, datetimeindex, WorkShops) => {
    let newSetSelectedItem = { ...selectedItem };
    let newDaysSelectedCount = daysSelectedCount;
    let WorkShopsStr = WorkShops.toLowerCase();
    if (WorkShopsStr === "yoga old students") {
      setNewStuAlert(
        {
          content: "Yoga - Old student: Advanced yoga, only for old students",
        },
        setTimeout(() => setNewStuAlert(null), 3000),
      );
    } else if (WorkShopsStr === "yoga new students") {
      setNewStuAlert(
        {
          content: "Yoga - New Student: Select if this is your first class with Varun",
        },
        setTimeout(() => setNewStuAlert(null), 3000),
      );
    } else if (WorkShopsStr === "lindy hop level 2") {
      setNewStuAlert(
        {
          content: "Lindy Hop Level 2: Select only if you did a session on Day 1 or 2",
        },
        setTimeout(() => setNewStuAlert(null), 3000),
      );
    }
    const curDay = singleDate.split("#")[1];
    if (event_dateid === 0) {
      newDaysSelectedCount[curDay] = newDaysSelectedCount[curDay] - 1;
      newSetSelectedItem = Object.keys(newSetSelectedItem)
        .filter((key) => key !== `${singleDate}#${time_keys}`)
        .reduce((acc, key) => {
          acc[key] = newSetSelectedItem[key];
          return acc;
        }, {});
    } else {
      if (!newSetSelectedItem[`${singleDate}#${time_keys}`]) {
        newDaysSelectedCount[curDay] = newDaysSelectedCount[curDay] + 1;
      }
      let searchIndex = refDivs.indexOf(datetimeindex);
      if (searchIndex >= 0 && refDivs[searchIndex + 1]) {
        setTimeout(() => {
          if (refDivs[searchIndex + 1] == 10) {
            var element = document.getElementById("goto2");
            element.scrollIntoView({ behavior: "smooth", block: "start" });
          } else if (refDivs[searchIndex + 1] == 10 || refDivs[searchIndex + 1] == 20) {
            var element = document.getElementById("goto3");
            element.scrollIntoView({ behavior: "smooth", block: "start" });
          } else {
            if (refDivs[searchIndex + 1] > 0) {
              var element = document.getElementById(refDivs[searchIndex + 1]);
              if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "start" });
              } else if (refDivs[searchIndex + 1] == 10 || refDivs[searchIndex + 1] == 20) {
                window.scrollTo(0, 0);
              }
            }
          }
        }, 1500);
      } else {
        setTimeout(() => {
          var element = document.getElementById("reviewButton");
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 1500);
      }
      newSetSelectedItem[`${singleDate}#${time_keys}`] = event_dateid;
    }
    setSelectedItem(
      Object.keys(newSetSelectedItem)
        .map((key) => {
          return { ...newSetSelectedItem[key], key: key };
        })
        .sort(
          (a, b) =>
            new Date(`${a.event_date} ${a.event_start_time}`) -
            new Date(`${b.event_date} ${b.event_start_time}`),
        ),
    );
    setDaysSelectedCount(newDaysSelectedCount);

    let arrayListItems = Object.keys(newSetSelectedItem)
      .map((key) => {
        return { ...newSetSelectedItem[key], key: key };
      })
      .sort(
        (a, b) =>
          new Date(`${a.event_date} ${a.event_start_time}`) -
          new Date(`${b.event_date} ${b.event_start_time}`),
      );
    arrayListItems.map((i, k) => {
      arrayListItems[i.key] = i;
    });
    arrayListItems.map((i, k) => {
      if (Number.isInteger(k)) {
        arrayListItems.splice(k);
      }
    });
    dispatch(
      workshopActions.addWorkshop({
        selectedEvents: arrayListItems,
      }),
    );
  };

  const handleWorkshopSubmit = () => {
    let allSelectedItems = { ...selectedItem };
    if (Object.keys(allSelectedItems).length === 0) {
      setShowAlert(
        {
          content: "Please select atleast any 1 Woskshop to continue.",
        },
        setTimeout(() => setShowAlert(null), 7000),
      );
    } else {
      setShowAlert({
        content: `"Are you sure want to continue with "${
          Object.keys(allSelectedItems).length
        }" workshops ?"`,
        btnText: "Confirm",
      });
    }
  };

  const closeAlert = () => {
    setShowAlert(null);
    setNewStuAlert(false);
  };
  const navigateBooking = () => {
    navigate("/selected-schedule");
  };

  const gotoNextTab = (jumpToIndex) => {
    setTabIndex(jumpToIndex);
    window.scrollTo(0, 0);
  };

  return (
    <>
      {showAlert && (
        <div className="alertPopUp">
          {showAlert.content}
          {showAlert.btnText && (
            <button type="button" className="btnSecondary" onClick={navigateBooking}>
              {showAlert.btnText}
            </button>
          )}
          <span className="apuClose" onClick={closeAlert}>
            X
          </span>
        </div>
      )}
      {newStuAlert && (
        <div className="alertPopUp">
          {newStuAlert.content}
          <button type="button" className="btnSecondary" onClick={closeAlert}>
            Close
          </button>
        </div>
      )}
      <Tabs className="worShpTab" selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          {evntData &&
            Object.keys(evntData).map((singleDate) => (
              <Tab kyey={singleDate}>
                {singleDate.toString().substr(0, 2)} <span>Day {singleDate.split("#")[1]}</span>
              </Tab>
            ))}
        </TabList>

        {evntData &&
          Object.keys(evntData).map((singleDate, dateIndex) => (
            <TabPanel className="wstConTab" key={singleDate}>
              {Object.keys(evntData[singleDate]).map((time_keys, timeIndex) => (
                <>
                  <p className="wctTime" id={`${dateIndex}${timeIndex}`}>
                    <span>{time_keys}</span> {/*| {singleDate.split("#")[0]} */}| CHOOSE ANY 1
                  </p>
                  {evntData[singleDate][time_keys].map((instructor, key) => (
                    <InstructorProfile
                      onCloseButton={() => {
                        itemClickedHandler(singleDate, time_keys, 0, `${dateIndex}${timeIndex}`);
                      }}
                      actClassName={
                        selectedItem[`${singleDate}#${time_keys}`]?.event_dateid ===
                        instructor.event_dateid
                          ? "btnSelected"
                          : ""
                      }
                      onProfileClick={() => {
                        itemClickedHandler(
                          singleDate,
                          time_keys,
                          instructor,
                          `${dateIndex}${timeIndex}`,
                          instructor.schedule_name,
                        );
                      }}
                      className={`${
                        selectedItem[`${singleDate}#${time_keys}`]?.event_dateid &&
                        selectedItem[`${singleDate}#${time_keys}`]?.event_dateid ===
                          instructor.event_dateid
                          ? "wctSelected"
                          : ""
                      } ${
                        selectedItem[`${singleDate}#${time_keys}`]?.event_dateid &&
                        selectedItem[`${singleDate}#${time_keys}`]?.event_dateid > 0 &&
                        selectedItem[`${singleDate}#${time_keys}`]?.event_dateid !==
                          instructor.event_dateid
                          ? "wctUnSelected"
                          : ""
                      }`}
                      buttonText={
                        selectedItem[`${singleDate}#${time_keys}`]?.event_dateid ===
                        instructor.event_dateid
                          ? "added"
                          : "add"
                      }
                      key={`${instructor.event_dateid}-${instructor.facilitator_name}`}
                      image={instructor.icon}
                      name={instructor.facilitator_name}
                      skills={instructor.schedule_name}
                      availableSlots={instructor.total_slots - instructor.booked_slots}
                    />
                  ))}
                </>
              ))}
            </TabPanel>
          ))}
      </Tabs>
      {tabIndex === 0 && (
        <div className="tabBtnCon" id="goto2">
          {daysSelectedCount[1] === totalDayWiseEventCount[1] && (
            <p className="tabDay">Day 1 Selection Complete</p>
          )}
          <button className="btn btn-primary btnWork" onClick={() => gotoNextTab(1)}>
            Go to Day 2
          </button>
        </div>
      )}
      {tabIndex === 1 && (
        <div className="tabBtnCon" id="goto3">
          {daysSelectedCount[2] === totalDayWiseEventCount[2] && (
            <p className="tabDay">Day 2 Selection Complete</p>
          )}
          <button className="btn btn-primary btnWork" onClick={() => gotoNextTab(2)}>
            Go to Day 3
          </button>
        </div>
      )}
      {tabIndex === 2 && (
        <div className="tabBtnCon" id="reviewButton">
          {daysSelectedCount[3] === totalDayWiseEventCount[3] && (
            <p className="tabDay">All Selection Complete</p>
          )}
          <button className="btn btn-primary btnWork" onClick={() => handleWorkshopSubmit()}>
            Review & Confirm
          </button>
        </div>
      )}
    </>
  );
};
export default WorkShops;

import axios from "axios";
import { useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../Constants";
import { authActions } from "../../store/auth";

import landingImage from "../../images/landingImage.png";
import logo from "../../images/logoMoon.png";

const Resistration = () => {
  const [emailValue, setEmailValue] = useState("");
  const [emptyError, setEmptyError] = useState("");
  const bookingStatus = useSelector((state) => state.auth.bookingStatus);
  const authEmail = useSelector((state) => state.auth.emailId);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const emailUpdated = (event) => {
    setEmailValue(event.target.value);
  };
  const submitRegistrationForm = (event) => {
    event.preventDefault();
    if (emailValue.trim().length === 0) {
      setEmptyError("Please enter the Your Email ID");
      return;
    }
    let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (!emailValue.trim().match(mailformat)) {
      setEmptyError("Please Enter valid Email ID");
      return;
    }
    var bodyFormData = new FormData();
    bodyFormData.append("email_id", emailValue);

    axios({
      method: "post",
      url: API_BASE_URL + "validate_user",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setEmptyError(response?.data?.message);
        dispatch(authActions.login());
        dispatch(authActions.getEmailHandler(emailValue));
        dispatch(authActions.getUserlHandler(response?.data?.data));
        localStorage.setItem("isLoggedIn", "1");
        if (response?.data?.data?.booking_status) {
          if (response?.data?.data?.booking_status === -1) {
            navigate("/work-shop");
          } else if (response?.data?.data?.booking_status === 0) {
            setEmptyError("Please try again after sometime");
          } else if (
            response?.data?.data?.booking_status === 1 ||
            response?.data?.data?.booking_status === 2
          ) {
            navigate("/my-schedules");
          }
        }
        navigate("/work-shop");
      })
      .catch(function (error) {
        setEmptyError(error?.response?.data?.message);
      });
  };
  useEffect(() => {
    if (localStorage.getItem("isLoggedIn") !== null && bookingStatus !== 0 && authEmail != "") {
      dispatch(authActions.login());
      navigate("/work-shop");
    } else if (isAuth) {
      setEmptyError("Unable to login. Please contact admin");
    }
  }, [localStorage]);

  return (
    <Fragment>
      <img src={logo} alt="moon festival" className="lanLogo" />
    <div className="regPage">
      <img src={landingImage} alt="background texture" />
      <form onSubmit={submitRegistrationForm}>
        <label htmlFor="emailId">Please enter email id to start selecting workshops </label>
        <div className="rpInputGroup">
          <p>Email Id</p>
          <input
            type="text"
            id="emailId"
            placeholder="For eg. varunsahu@gmail.com"
            onChange={emailUpdated}
            value={emailValue}
          />
        </div>
        <p className="inpErr">{emptyError}</p>
        <button className="btn btn-primary">Confirm</button>
      </form>
    </div>
    </Fragment>
  );
};

export default Resistration;

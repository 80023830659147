import { Fragment } from "react";
import "./App.css";
import LandingPage from "./components/LandingPage";
import { Route, Routes } from "react-router-dom";
import WorkShops from "./components/pages/WorkShop";
import Navigation from "./components/Navigation";
import Facilitators from "./components/pages/Facilitators";
import Registration from "./components/pages/Registration";
import FacilitatorDetails from "./components/pages/FacilitatorDetails";
import Deets from "./components/pages/Deets";
import SelectedSchedule from "./components/pages/SelectedSchedule";
import ThankYou from "./components/pages/ThankYou";

const App = () => {
  return (
    <Fragment>
      <Navigation />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/work-shop" element={<WorkShops />} />
        <Route path="/about-workshop/" element={<Facilitators />} />
        <Route path="/facilitator/:facilatorId" element={<FacilitatorDetails />} />
        {/* <Route path="/about-workshop/*" element={<Facilitators />} >
          <Route path=':facilatorId/facilitator-details' element={<FacilitatorDetails />} />
        </Route> */}
        <Route path="/registration" element={<Registration />} />
        <Route path="/deets" element={<Deets />} />
        <Route path="/selected-schedule" element={<SelectedSchedule />} />
        <Route path="/my-schedules" element={<ThankYou />} />
      </Routes>
    </Fragment>
  );
};

export default App;

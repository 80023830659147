import { createSlice } from "@reduxjs/toolkit";

const workshopSlice = createSlice({
  name: "selectedWorkshop",
  initialState: {
    workshop: {},
  },
  reducers: {
    addWorkshop(state, action) {
      state.workshop = { ...action.payload.selectedEvents };
    },
  },
});

export default workshopSlice.reducer;

export const workshopActions = workshopSlice.actions;

import { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import NavDirection from "../UI/NavDirection";
import { API_BASE_URL } from "../../Constants";

const FacilitatorDetails = () => {
  const [facilitators, setFacilitators] = useState({});
  const params = useParams();
  useEffect(() => {
    axios.get(API_BASE_URL + `facilitators/${params.facilatorId}`).then((response) => {
      setFacilitators(response?.data);
    });
  }, []);

  return (
    <Fragment>
      <NavDirection />
      {facilitators && (
        <>
          <p>{params.facilitator_name}</p>
          <div className="facilitatorCon">
            <img src={facilitators.banners} alt="yoga trainer" />
            <h4>{facilitators.facilitator_name}</h4>
            <h5>{facilitators.experties} </h5>
            <p>
              <span dangerouslySetInnerHTML={{ __html: facilitators.description }} />
            </p>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default FacilitatorDetails;

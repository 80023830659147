import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import aboutMoon from '../../images/yoga.png'
import aboutMoonAct from '../../images/yogaAct.png'
import phone from '../../images/phone.png'
import phoneAct from '../../images/phoneAct.png'
import location from '../../images/location.png'
import locationAct from '../../images/locationAct.png'
import bus from '../../images/bus.png'
import train from '../../images/train.png'
import flight from '../../images/flight.png'
import deetsImg1 from '../../images/deetsImg1.JPG'
import deetsImg2 from '../../images/deetsImg2.JPG'
import deetsImg3 from '../../images/deetsImg3.JPG'

const Deets = () => {
  console.log(localStorage.getItem("isLoggedIn"))
  return (
    <Tabs className="dateTabs deetsTabs">
      <TabList>
        <Tab>
          <div className="dtIcons">
            <img src={aboutMoon} alt="About Moon" />
            <img src={aboutMoonAct} alt="About Moon" />
          </div>
          About MOON
        </Tab>
        <Tab>
          <div className="dtIcons">
            <img src={location} alt="Location" />
            <img src={locationAct} alt="Location" />
          </div>
          GETTING THERE
        </Tab>
        <Tab>
          <div className="dtIcons">
            <img src={phone} alt="Contact Us" />
            <img src={phoneAct} alt="Contact Us" />
          </div>
          CONTACT US
        </Tab>
      </TabList>

      <TabPanel>
        <div className="deetsCon">
          <h4>
            EMBRACE & CELEBRATE YOURSELF AT MOON – A CONSCIOUS YOGA, HEALING,
            ART & MUSIC FESTIVAL
          </h4>
          <div class="video-wrapper">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/5e21yGKqs34?controls=0&showinfo=0&rel=0&autoplay=1&loop=1"
              title="Moon Yoga Festival 2022 - Goa, India | Teaser 1"
              allowfullscreen
            ></iframe>
          </div>
          <p>
            Moon is a{" "}
            <b>
              three day conscious festival celebrating yoga, dance, music,
              healing & art to help you on your journey of living a holistic
              lifestyle.
            </b>{" "}
            Conceptualized by Varun Sahu who has been teaching Yoga since the
            past 8 years across 5 countries. He started the concept of Yoga
            adventures in India – taking students deep into forests, jungles and
            into untouched nature, combining meditation with thrilling
            adventures.
          </p>
          <img src={deetsImg1} alt="Dancer" className="detImg" />
          <p>
            The Moon symbolizes things such as our inner feelings and desires,
            our shadow self, and things that we are yet to discover about
            ourselves. Yet, with the gentle illumination that the Moon brings,
            we can come to learn these things about ourselves and integrate them
            without too much strife. We take this trip to the moon to celebrate,
            sing, dance, play, embrace and feel better together.
          </p>
          <div class="video-wrapper">
            <iframe
              src="https://www.youtube.com/embed/lf4CQGVRsV0?controls=0&showinfo=0&rel=0&autoplay=1&loop=1"
              title="Moon Yoga Festival 2022 - Goa, India | Moon Goddess Teaser"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <p>
            This is the <b>first edition of MOON</b> bringing facilitators
            across various art forms under one roof. With 
            <b> 75+ workshops, 15+ Facilitators,</b> art forms from around the
            world, <b>20+ Musicians</b> & a beautiful location in South Goa,
            MOON is surely the place to be!
          </p>
          <img src={deetsImg2} alt="Dancer" className="detImg" />
          <p>
            From movement workshops such as Yoga, Qi Gong, Capoeira, Sufi,
            Somatic dance, ecstatic dance, Hula Hoop to art-based workshops such
            as Intuitive art, pottery, linocut to music performances – this
            festival is set to bring{" "}
            <b>
              yoga enthusiasts, music lovers, artists & spiritual seekers from
              across the country under one roof.
            </b>
          </p>
          <img src={deetsImg3} alt="Dancer" className="detImg" />
          <p>
            So fasten your seatbelts and be prepared, as we’re all set to launch
            this spaceship and make you land straight on the moon!
          </p>
        </div>
      </TabPanel>
      <TabPanel>
        <p>
          <b>Festival Venue:</b> Bhakti Kutir - Palolem Beach
        </p>
        <p>
          <a href="https://g.page/bhaktikutirgoa?share">Google Maps</a>
        </p>
        <p>
          <b>Address:</b> 296 Colomb, Palolem, Canacona, Goa 403702
        </p>
        <p>
          <b>Phone:</b> 098236 27258
        </p>
        <div className="dticoImg">
          <img src={flight} alt="Flight" className="dtGetLoc" />
          <p>
            Distance from the Airport Goa Airport is 1.5 hrs away from Palolem
            Beach - Festival Venue.
          </p>
        </div>
        <div className="dticoImg">
          <img src={train} alt="Flight" className="dtGetLoc" />
          <p>Madgaon Railway Station is 1 hr away from Palolem Beach.</p>
        </div>
        <div className="dticoImg">
          <img src={bus} alt="Flight" className="dtGetLoc" />
          <p>Mapusa Bus Stand is 2 hrs away from Palolem Beach.</p>
        </div>
      </TabPanel>
      <TabPanel>
        <h5 className="apTitle">Get in touch:</h5>
        <p>
          <b>Varun</b> - +919820191100
        </p>
        <p>
          <b>Deepika</b> - +919820191203
        </p>
        <p>
          <b>Karan</b> - +919930920313
        </p>
      </TabPanel>
    </Tabs>
  );
}

export default Deets
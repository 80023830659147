import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { API_BASE_URL } from "../../Constants";
import checkCircle from "../../images/checkCircle.png";
import NavDirection from "../UI/NavDirection";
import { useNavigate } from "react-router-dom";
import ScheduledWorkshop from "../UI/ScheduledWorkshop";

const ThankYou = () => {
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const bookingStatus = useSelector((state) => state.auth.bookingStatus);
  const authEmail = useSelector((state) => state.auth.emailId);
  const [schedules, setSchedules] = useState();
  const [allSchedules, setAllSchedules] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth) {
      navigate("/registration");
    } else {
      if (bookingStatus === 0) {
        navigate("/registration");
      }
      axios.get(API_BASE_URL + "schedules?emailId=" + authEmail).then((response) => {
        if (response?.data?.schedules) {
          setSchedules(response?.data?.schedules);
        }
      });
    }
  }, [isAuth]);

  useEffect(() => {
    if (schedules) {
      const countAr = [];
      countAr[1] = [];
      countAr[2] = [];
      countAr[3] = [];
      let newSchedules = countAr;
      schedules.map((schedule, index) => {
        newSchedules[schedule.event_day].push(schedule);
        // console.log("schedule", schedule);
      });
      setAllSchedules(newSchedules);
    }
  }, [schedules]);

  return (
    <div className="mySchedule">
      <NavDirection />
      {bookingStatus === 1 && (
        <>
          <div className="thankYou">
            <img src={checkCircle} alt="Thanks icon" />
            <p>GOOD ONE! YOUR SCHEDULE IS UNDER REVIEW</p>
          </div>
          <p>Once reviewed, you will receive it on your email ID</p>
        </>
      )}

      {bookingStatus === 2 &&
        allSchedules &&
        allSchedules.map((schedules) => (
          <div className="facilitators">
            <h4 className="msDate">{`DAY ${schedules[0]?.event_day}, ${schedules[0]?.display_date}`}</h4>
            {schedules.length > 0 &&
              schedules.map((schedule) => (
                <>
                  <ScheduledWorkshop
                    time={`${schedule?.event_start_time}-${schedule?.event_end_time}`}
                    key={schedule?.event_dateid}
                    name={schedule?.facilitator_name}
                    image={schedule?.icon}
                    skills={schedule?.schedule_name}
                    actions={false}
                  />
                </>
              ))}
          </div>
        ))}
    </div>
  );
};

export default ThankYou;

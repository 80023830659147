import { NavLink } from "react-router-dom";
import workShop from "../images/workShop.png";
import aboutWork from "../images/aboutWork.png";
import deets from "../images/deets.png";
import workShopAct from "../images/workShopAct.png";
import aboutWorkAct from "../images/aboutWorkAct.png";
import deetsAct from "../images/deetsAct.png";
import schedule from "../images/schedule.png";
import scheduleAct from "../images/scheduleAct.png";
import { useSelector } from "react-redux";

const Navigation = () => {
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const isSheduled = useSelector((state) => state.auth.scheduledStatus);
  return (
    <ul className="navSticky">
      {!isSheduled && (
        <li>
          <NavLink
            className={(navData) => (navData.isActive ? "navActive" : "")}
            to={isAuth ? "/work-shop" : "/registration"}
          >
            <img src={workShop} alt="workshop Yoga" />
            <img src={workShopAct} alt="workshop Yoga" className="nsAct" />
            pick WSHOP
          </NavLink>
        </li>
      )}
      <li>
        <NavLink
          className={(navData) => (navData.isActive ? "navActive" : "")}
          to="/about-workshop"
        >
          <img src={aboutWork} alt="Instructors" />
          <img src={aboutWorkAct} alt="Instructors" className="nsAct" />
          ABOUT WSHOP
        </NavLink>
      </li>
      {isSheduled && (
        <li>
          <NavLink
            className={(navData) => (navData.isActive ? "navActive" : "")}
            to="/my-schedules"
          >
            <img src={schedule} alt="Terms and conditions" />
            <img
              src={scheduleAct}
              alt="Terms and conditions"
              className="nsAct"
            />
            MY Schedule
          </NavLink>
        </li>
      )}
      <li>
        <NavLink
          className={(navData) => (navData.isActive ? "navActive" : "")}
          to="/deets"
        >
          <img src={deets} alt="Terms and conditions" />
          <img src={deetsAct} alt="Terms and conditions" className="nsAct" />
          Details
        </NavLink>
      </li>
    </ul>
  );
};

export default Navigation;

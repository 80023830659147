import axios from "axios";
import { useState, useEffect, Fragment } from "react";
import { API_BASE_URL } from "../../Constants";
// import { Link, Outlet } from "react-router-dom";
import aboutWorkShop from "../../images/aboutWork.png";
import Facilitator from "../UI/Facilitator";
import Musicians from "../UI/Musicians";
import NavDirection from "../UI/NavDirection";

const Facilitators = () => {
  const [facilitators, setFacilitators] = useState([]);
  useEffect(() => {
    axios.get(API_BASE_URL + "facilitators").then((response) => {
      setFacilitators(response?.data);
    });
  }, []);
  return (
    <Fragment>
      <NavDirection />
      <div className="aboutFacilitators">
        <h3 className="afTitle">
          <img src={aboutWorkShop} alt="about Work Shop" />
          ABOUT FACILITATORS
        </h3>
        <div className="facilitators">
          {facilitators.map((facilitator) => (
            <Fragment>
              <Facilitator
                key={`${facilitator.facilitator_id}_${facilitator.facilitator_name}`}
                facilitator={facilitator}
              />
            </Fragment>
          ))}
        </div>
        <Musicians />
      </div>
    </Fragment>
  );
};

export default Facilitators;

import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

const ScheduledWorkshop = (props) => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="ficilitator">
        <img src={props.image} alt={props.name} />
        <p className="ficTime">{props.time}</p>
        <h4>{props.name}</h4>
        <p>{props.skills}</p>
        {props.actions && (
          <button
            onClick={() => {
              navigate("/work-shop");
            }}
            className="btnTransparent"
          >
            Edit
          </button>
        )}
      </div>
      {/* <div className="msSchItems">
        <div className="msiFaci">
          <img src={props.image} alt="trainer" className="msfImg" />
          <div>
            <h5 className="msfName">{props.name}</h5>
            <p className="msfSkills">{props.skills}</p>
          </div>
        </div>
        {props.actions && (
          <>
            <img src={checkCircle} alt="check circle" className="msiCheck" />
            <button
              onClick={() => {
                navigate("/work-shop");
              }}
              className="btnTransparent"
            >
              Edit
            </button>
          </>
        )}
      </div> */}
    </Fragment>
  );
};

export default ScheduledWorkshop;

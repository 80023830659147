import timesIcon from "../../images/timesIcon.png";
import timesIconA from "../../images/timesIconA.png";

const InstructorProfile = (props) => {
  return (
    <div className="insProCon">
      <button onClick={props.onCloseButton} className={props.actClassName}>
        <img src={timesIcon} alt="close icons" className="buTimes" />
        <img src={timesIconA} alt="close icons" className="buAct" />
      </button>
      <div
        className={props.className}
        onClick={props?.availableSlots > 0 ? props.onProfileClick : null}
      >
        <img src={props.image} alt={props.name} className="ipcInsImg" />
        <h3 className="ipcName">{props.name}</h3>
        <p className="ipcSkills">{props.skills}</p>
        {props?.availableSlots <= 0 ? (
          <p className="ipcSlots">Slots Full</p>
        ) : (
          <button type="button">+ {props.buttonText}</button>
        )}
      </div>
    </div>
  );
};

export default InstructorProfile;

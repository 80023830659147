import { createSlice } from "@reduxjs/toolkit";

const initialAuthState = {
  isAuthenticated: false,
  emailId: "",
  scheduledStatus: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    login(state) {
      state.isAuthenticated = true;
    },
    logout(state) {
      state.isAuthenticated = false;
    },
    getEmailHandler(state, action) {
      state.emailId = action.payload;
    },
    getUserlHandler(state, action) {
      state.bookingStatus = parseInt(action.payload.booking_status);
      state.userId = parseInt(action.payload.user_id);
    },
    scheduledHandler(state) {
      state.scheduledStatus = true;
    },
    unScheduledHandler(state){
      state.scheduledStatus = false;
    }
  },
});

export default authSlice.reducer;

export const authActions = authSlice.actions;

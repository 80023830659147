import { configureStore } from "@reduxjs/toolkit";
import authReducer from './auth'
import workshopReducer from "./selectedWorkshop";

const store = configureStore({
  reducer: {
    auth: authReducer,
    selectedWorkshop: workshopReducer
  }
})

export default store